import React from 'react';
import Layout from '../components/Layout';
import Pagination from '@sentry/static-shared/components/Pagination';
import SEO from '../components/SEO';
import { graphql, Script } from 'gatsby';
import Layer from '@sentry/static-shared/components/layer-next';
import { white, bgLight } from '@sentry/static-shared/utils/css/colors';
import BlogHeader from '../components/BlogHeader';
import { HaloPortrait } from '@sentry/static-shared/components/Portrait';
import Social from '@sentry/static-shared/components/Social';
import BlogPostGrid from '../components/BlogPostGrid';

import styled from '@emotion/styled';
import DOMPurify from 'isomorphic-dompurify';
import { mqMin, mqMax } from '@sentry/static-shared/utils/css';
import * as colors from '@sentry/static-shared/utils/css/colors';

import { randomFrom } from '@sentry/static-shared/utils/utils';

const AuthorPage = ({
  data: {
    contentfulPerson,
    allContentfulBlogPost,
    contentfulPage: blogData,
    site: {
      siteMetadata: { siteUrl, twitterHandle },
    },
  },
  pageContext,
  location,
}) => {
  const defaultAvatars = blogData.dataModules.find(
    x => x.slug === 'fallback-avatars'
  ).assets;

  const author = contentfulPerson;
  const authorBio = contentfulPerson?.bio?.childMarkdownRemark?.html;
  const blogHeader = <BlogHeader overlap="left" disablePaddingBottom={true} />;
  const haloColor = colors[author.haloColor] || colors.mdPink;

  author.avatar = author.avatar || randomFrom(defaultAvatars);

  const createProfilePageSchema = () => {
    const getSameAs = () => {
      const urls = {
        twitter: 'https://twitter.com/',
        linkedin: 'https://www.linkedin.com/in/',
        github: 'https://github.com/'
      };
      return Object.entries(urls)
        ?.map(([handle, url]) => author[handle] && `${url}${author[handle]}`)
        ?.filter(Boolean)
    };

    const schema = {
      "@context": "https://schema.org",
      "@type": "ProfilePage",
      "mainEntity": {
        "@type": "Person",
        "name": author?.name,
        "alternateName": author?.twitter || author?.linkedin || author?.github
      },
      "description": `${author?.name}, ${author?.title}`,
      "image": author?.avatar?.file?.url,
      "sameAs": getSameAs()
    }

    return JSON.stringify(schema);
  };

  return (
    <Layout CustomBlogHeader={blogHeader}>
      <SEO
        title={`Posts by ${author.name}`}
        image={author.avatar}
        canonical={`${siteUrl}${location.pathname}`}
        twitterHandle={author.twitter}
        description={`${author.name} has written ${
          allContentfulBlogPost.edges.length
        } post${
          allContentfulBlogPost.edges.length === 1 ? '' : 's'
        } on the Sentry blog.`}
      />
      <Layer backgroundColor={white} clipTop="slopeRight">
        <StyledRow>
          {author.avatar ? (
            <StyledHaloPortrait
              src={author.avatar.file.url}
              haloColor={haloColor}
            />
          ) : (
            'Missing portrait'
          )}
          <StyledMeta>
            <StyledAuthorName>{author.name}</StyledAuthorName>
            <h3>{author.title}</h3>
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(authorBio)}}/>
            <StyledSocial
              twitter={author.twitter}
              github={author.github}
              linkedin={author.linkedin}
            />
          </StyledMeta>
        </StyledRow>
      </Layer>
      <StyledMain
        tag="main"
        backgroundColor={bgLight}
        clipTop="slopeLeft"
        paddingBottom
      >
        <h3>Blogs Published by {author.name}</h3>
        <BlogPostGrid nodes={allContentfulBlogPost.edges.map(x => x.node)} />
        <Pagination pageContext={pageContext} />
      </StyledMain>
      <Script className="structured-data-list" type="application/ld+json">
        {createProfilePageSchema()}
      </Script>
    </Layout>
  );
};

export default AuthorPage;

const StyledMain = styled(Layer)`
  h3:first-of-type {
    text-align: center;
    padding-bottom: 2rem;
  }
  flex: 1;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mqMin('sm')} {
    align-items: center;
    flex-direction: row;
  }
`;

const StyledMeta = styled.div`
  flex-grow: 1;
  ${mqMin('sm')} {
    padding-left: 3rem;
  }
`;

const StyledAuthorName = styled.h1`
  margin-bottom: 0.5rem;
  line-height: 1;
  text-align: center;

  ${mqMin('sm')} {
    text-align: left;
  }
`;

const StyledHaloPortrait = styled(HaloPortrait)`
  flex-shrink: 0;
  ${mqMax('sm')} {
    width: 30%;
  }
  ${mqMax('sm')} {
    width: 20%;
    margin-bottom: 1rem;
  }
  ${mqMin('sm')} {
    flex-basis: 100px;
  }
`;

const StyledSocial = styled(Social)`
  justify-content: center;
  padding-bottom: 0 !important;

  ${mqMin('sm')} {
    justify-content: flex-start;
  }
`;

export const query = graphql`
  fragment Author on ContentfulPerson {
    name
    title
    bio {
      childMarkdownRemark {
        html
      }
    }
    twitter
    github
    linkedin
    avatar {
      ...Image
    }
    haloColor
    blog_post {
      id
    }
    fields {
      url
    }
  }

  query($slug: String!, $posts: [String!]) {
    site {
      siteMetadata {
        siteUrl
        twitterHandle
      }
    }
    contentfulPerson(slug: { eq: $slug }) {
      ...Author
    }
    allContentfulBlogPost(
      filter: { slug: { in: $posts } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    # Global Blog Data
    contentfulPage(contentful_id: { eq: "6PaNQpeuz5DLUwC3KP09R5" }) {
      dataModules {
        slug
        assets {
          ...Image
        }
      }
    }
  }
`;
